<template>
  <div>
    <Upload id="iviewUp" :max-size="20480" :show-upload-list="false" :on-progress="handleProgressQuill" :on-success="handleSuccessQuill" :on-error="handleErrorQuill" :format="['jpg', 'jpeg', 'png', 'pdf']" :headers="header" :multiple="multiple" :data="data" name="richTextAccessory"  :action="url" :on-format-error="onFormatError">
      {{ label }}
    </Upload>
    <Spin fix v-show="loading">
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <!-- <div>上传中...</div> -->
    </Spin>
  </div>
</template>

<script>
import http from '@/service/baseUrl'
export default {
  name: 'formUpload',
  props: {
    label: String,
    multiple: Boolean || false,
    httpUrl: '',
    data: {}
  },
  data() {
    return {
      loading: false,
      url: '',
      header: ''
    }
  },
  created() {
    this.url = this.httpUrl ? http.http + this.httpUrl : http.http + this.$api.uploadFile.url
    // console.log(this.url)
    this.header = {
      // Authorization: 'Bearer ' + localStorage.getItem('ohoqicbi==')
      Authorization: 'Bearer ' + localStorage.getItem('ohoqicbi==')
    }
    // console.log(this.header)
    console.log(this.data, 'data')
  },
  methods: {
    // 文件类型校验
    onFormatError() {
      this.$Message.error('只允许上传jpg,jpeg,png,pdf格式的文件')
    },
    // 上传失败
    handleErrorQuill() {
      this.loading = false
      this.$Message.error('文件上传失败')
      // this.$Notice.error({
      // 	title: '失败',
      // 	desc: '文件上传失败'
      // })
    },
    // 上传成功
    handleSuccessQuill(res) {
      // console.log(res)
      this.loading = false
      let key = res.data[0].file_key
      let file_name = res.data[0].file_name
      // 把key返回
      this.$emit('get-key', key, file_name, res.data[0].file_url)
    },
    // 上传中
    handleProgressQuill() {
      this.loading = true
    }
  }
}
</script>

<style scoped lang="less">
#iviewUp {
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  text-align: center;
  box-sizing: border-box;
}
/deep/ .ivu-upload-select {
  width: 100% !important;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>
